import React from "react";
// styles
import "./HeroSection.css" 

function HeroSection() {
    return (
        // div with background image
        <div className="hero-container">
        </div>
    )
}

export default HeroSection
